@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;

@tailwind components;

@tailwind utilities;


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    scroll-behavior: smooth;
    color-scheme: light;
  }


@layer components {

    /* *,*::after,*::before, body{
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    } */
    
    .block--skewed-right {
        /* padding-bottom: calc(1rem); */
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 80%);
    }

    .block--skewed-left {
        /* padding-bottom: calc(1rem); */
        /* clip-path: polygon(0% 0%, 100% 0%, 100% 80%, 0% 100%); */
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%,9% 90%, 30% 60%, 0% 100%);
        
    }


    .input{
        @apply  bg-white mt-2 block w-full px-3 py-2 border border-gray-300 rounded-lg focus:border-[#004C2B]
        outline-none focus:outline-none  focus:shadow-radiography-shadow focus:transition-all focus:duration-300
    }

    .button {
        @apply bg-blue-400 hover:bg-blue-600 active:bg-blue-800 text-white py-2 px-3 
        cursor-pointer rounded-md active:scale-95 transition ease-in-out duration-300 ;
    }

    .nav__item{
        @apply hover:text-white 
    }

    .nav__item2 {
        @apply border-t hover:text-white
    }

    .link {
        @apply cursor-pointer hover:underline;
    }

    .icon {
        @apply   w-5 md:w-8 h-5 md:h-8 ;
    }


   
}

/* // styling the scrollbar */
@layer utilities {
     .scrollbar::-webkit-scrollbar {
      width: 10px;
      height: 20px;
    }
  
    .scrollbar::-webkit-scrollbar-track {
      border-radius: 20px;
      background: #f7f4ed;
      width: 10px;
      height: 20px;
    } 
  
   .scrollbar::-webkit-scrollbar-thumb {
      background: #44484f;
      border-radius: 100vh;
      border: 3px solid #f6f7ed;
    }
  
    .scrollbar::-webkit-scrollbar-thumb:hover {
      background: #44484f;
    } 
  }

 /* other styles  */
.angles {
    width: 26px;
    height: 26px;

    position: absolute;
    top: 0px;
    left: 0px;
    border-left: 5px solid #C92B27;
    border-top: 5px solid #C92B27;
}

.angles2 {
    width: 26px;
    height: 26px;

    position: absolute;
    bottom: 0px;
    right: 0px;
    border-right: 5px solid #009856;
    border-bottom: 5px solid #009856;
}

.serviceBox1 {
    border-radius: 100px 0px 0px 0px;
    opacity: 0.15000000596046448;
    background: #C92B27;
}

.serviceBox2 {
    border-radius: 150px 0px 0px 0px;

}

.bad {
    border-radius: 10px;
    opacity: 0.20000000298023224;
    box-shadow: 14px 20px 48px 0px rgba(25, 15, 44, 0.02);
}

.bgkla{
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.bgkla1{
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

/* custom-image.css */
.blur-on-load {
    filter: blur(5px); /* Adjust the blur amount as needed */
    transition: filter 0.3s ease-in-out; /* Add a smooth transition effect */
  }

